import React, { useState } from "react";


export default function EditTerminalInfo(props) {
  const { handleChange, golvlUpdateData, info} = props;
  // const [disabled, setDisabled] = useState(true);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  // const [btnEdit, setBtnEdit] = useState("Редагувати");

  // const edit = () => {
  //   setDisabled((disabled) => !disabled);
  //   save();
  // };
  // const save = () => {
  //   if (btnEdit === "Редагувати") {
  //     setBtnEdit("Зберегти");
  //   } else setBtnEdit("Редагувати");
  // };

  return (
    <>
      <div className="m-2">
    
        <form>
          <label className="label">Назва локації</label>
          <input
            className="input"
            type="text"
            disabled={true}
            value={info.location.locationName}
            name="locationName"
            onChange={handleChange}
          />

          <label className="label">Модель терміналу</label>
          <input
            className="input"
            type="text"
            disabled={true}
            value={info.terminal.model}
            name="model"
            onChange={handleChange}
          />

          <label className="label">Банк клієнта</label>
          <input
            className="input"
            type="text"
            disabled={true}
            value={info.client.clientName}
            name="clientName"
            onChange={handleChange}
          />

          <label className="label">Контактна особа</label>
          <input
            onChange={handleChange}
            className="input !placeholder-gray-800 "
            type="text"
            disabled={false}
            placeholder={info.locationAddress.locationContactName}
            // value={info.locationAddress.locationContactName}
            name="locationContactName"
            
          />

          <label className="label">Номер контактної особи</label>
          <input
            onChange={handleChange}
            className="input !placeholder-gray-800"
            type="text"
            disabled={false}
            // value={contactPhone}
            placeholder={info.locationAddress.locationContactPhone}
            
            name="locationContactPhone"
            
          />
        </form>
        {/* зона формы */}
      </div>
      {/* зона кнопок */}
      <div className="space-y-2">

        <button
          onClick={golvlUpdateData}
          // className="items-center justify-center w-full h-12 px-6 pt-2 m-2 font-medium tracking-wide text-white transition duration-200 bg-green-700 rounded-lg hover:bg-green-800 focus:shadow-outline focus:outline-none"
          className="btn-blck"
        >
         Зберегти зміни
        </button>
      </div>
    </>
  );
}
