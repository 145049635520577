import React from "react";

export default function Forma(props) {
  //пропси
  const { data, handleChange, goNextLevel } = props;

  return (
    <>
      <form>
        <p>
          Для індентифікації відправте мені, будь ласка, серійний номер вашого
          терміналу.
        </p>
        <label className="label">Cерійний номер</label>
        <input
          onChange={handleChange}
          className="input"
          type="text"
          placeholder="Введіть серійний номер"
          name="serial"
          value={data.serial}
        />

        {/* info section */}
        <div className="mx-3">
          <span className="font-semibold text-red-500 dark:text-blue-400">
            Важливо!
          </span>
          <p className="text-sm italic text-gray-600 dark:text-gray-200">
            Cерійний номер водьте без «-» і прогалин (серійник знаходиться
            позаду на кришці або під кришкою терміналу, після напису s/n)
          </p>
        </div>
        <label className="label">ID номер терміналу</label>
        <input
          onChange={handleChange}
          className="input"
          type="text"
          placeholder="Введіть ID номер терміналу"
          name="terminalID"
          value={data.terminalID}
        />
        {/* info section */}
        <div className="mx-3">
          <span className="font-semibold text-red-500 dark:text-blue-400">
            Важливо!
          </span>
          <p className="text-sm italic text-gray-600 dark:text-gray-200">
            Тепер напишіть ID вашого терміналу.{" "}
            <b>Цей параметр вказаний на кожному чеку</b>(починається з літер «P,
            S, K, R, H, D, F, B», або 8 цифр та складається з 8 символів)
          </p>
        </div>
        <button
          onClick={goNextLevel}
          disabled={!data.serial || !data.terminalID}
          className="btn-blck disabled:opacity-0 disabled:cursor-default"
        >
          Ідентифікуватися
        </button>
      </form>
    </>
  );
}
