import React from "react";
import Taska1 from "../ui/Taska1";
const HelpMsg = (props) => {
  const {golvlOrder,  orderTask,goBackLevel} = props;

  return (
    <>
    <div>
      <p className="text-base font-bold text-center">
      Виконайте, будь ласка, перелічені дії, поки проблема не буде вирішена 👇
      </p>
    </div>
  <Taska1 orderTask={orderTask}/>

      <div className="">
      <button onClick={goBackLevel} className="btn-servise">
                👈Крок назад
      </button>

          <button className="btn btn-blck" onClick={golvlOrder}>
            Нічого не допомогою
          </button>
        </div>
    </>
  );
};
export default HelpMsg;
