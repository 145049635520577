import React from "react";

export default function CreateOrder(props) {
  const {
    goPaper,
    goService,
    golvlInfoTerminal,
    } = props;

  return (
    <>
      <div className="w-full p-4 font-light text-gray-600 bg-white text-md">
        <p>
          Виберіть тепер у нижньому меню, що потрібно зробити з цим терміналом
        </p>
        <p className="font-bold ">За допомогою даного сервісу можна:</p>
        {/* кпопки вибору jobType */}
        <ul>
          <button onClick={goPaper} className="btn-servise">
            🖨 Замовлення паперу
          </button>
          <button onClick={goService} className="btn-servise">
            💳 Не працює термінал
          </button>
          <a href="tel:0800501359" className="btn-servise">
            📢 Зв'язатися з диспетчером
          </a>
        </ul>
        <button onClick={golvlInfoTerminal} className="btn-servise">
                👈Крок назад
        </button>
      </div>
    </>
  );
}
