import React from 'react';

export default function BanerAlert(props) {

  
  const txt = `Перевірте, будь ласка, чи правильно визначений термінал та контактна особа.
  У разі необхідності - відредагуйте контактну інформацію`

    return (
        <>
        <div className="bg-white">
    <div className="px-4 py-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        
  <div className="p-4 rounded-md bg-blue-50">
    <div className="flex">
      <div className="flex-shrink-0">
        <svg className="w-5 h-5 text-blue-400" data-todo-x-description="Heroicon name: solid/information-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path>
</svg>
      </div>
      <div className="flex-1 ml-3 md:flex md:justify-between">
        <p className="text-sm text-blue-700">
          
          {txt}

        </p>
   
      </div>
    </div>
  </div>

      </div>
    </div>
  </div>
    {/* <div className="italic tracking-tight text-center text-gray-900 border-2 border-red-400 rounded-full">
            <p className="font-bold">Увага!</p>
            <p className="antialiased ">
            Перевірте, будь ласка, чи правильно визначений <b>термінал</b> та <b>контактна особа.</b>            </p>
            <p>
            У разі необхідності - <b>відредагуйте</b> контактну інформацію
            </p>
        </div> */}
      {/* <div className="flex flex-col px-6 py-3 bg-indigo-600 rounded-full">
             <p className="ml-3 font-medium text-white">
                <span className="">We announced a new product!</span>
                <span className="">Big news! We're excited to announce a brand new product.</span>
              </p>
       
      </div> */}
      </>
    )
  }