import React from "react";
import Terminal from "./Terminal";

export default function InfoTerminal(props) {
  //функція
  const { data, handleChange, golvlCreateOrder, goStart, info, setInfo, golvlUpdateData, golvlEditTerminalInfo} = props;
// console.log(data)
  return (
    <>
      <Terminal 
      data={data}
      goStart={goStart}
      golvlCreateOrder={golvlCreateOrder}
      handleChange={handleChange}
      info={info}
      setInfo={setInfo}
   
      golvlUpdateData={golvlUpdateData}
      golvlEditTerminalInfo={golvlEditTerminalInfo}
       />

  
    </>
  );
}
