import React from 'react';

const JobTypeService = (props) => {
    
    const {goStart, taska1, taska2, taska3, goBackLevel}=props
    
  
    
        return (
            <>
              <div className="flex flex-col">
          <p className="m-2">Виберіть, будь ласка, варіант опису проблеми</p>
          <ul>
            <button onClick={taska1}  className="btn-servise">
            Проблема зі зв'язком
              </button>
           
            <button onClick={taska2} className="btn-servise">
            Tamper (Alert Irruption)
              </button>

            <button onClick={taska3} className="btn-servise">
            Не проводить оплати
              </button>

              <button onClick={goStart} className="btn-servise">
              ▶ На початок
              </button>

              <button onClick={goBackLevel} className="btn-servise">
                👈Крок назад
              </button>

    </ul>
          <h3> </h3>
      
    
    
        </div>
    
       
            </>
        )
    }
export default JobTypeService