import React from "react";

const JobTypePaper = (props) => {
  const { data, golvlOrder, handleChange,goBackLevel } = props;

  return (
    <>
      <div className="flex flex-col">
        <p className="m-2">Введіть цифрами необхідну кількість рулонів</p>
        <input
          className="m-2 select"
        
          placeholder="Введіть кількість рулонів"
          onChange={handleChange}
          inputmode="numeric"
          type="number"
          name="rollCount"
         
        >
          {/* console.log({data.rollCount}) */}
        </input>
        <button className="btn btn-blck" onClick={golvlOrder}>
          Сформувати
        </button>

        <h3>Кількість рулонів на доставку : {data.rollCount} </h3>
      </div>
      <button onClick={goBackLevel} className="btn-servise">
                👈Крок назад
      </button>
    </>
  );
};
export default JobTypePaper;
