import React from "react";

const Hello = () => {

return(
<div className="w-full p-4 font-light text-gray-600 bg-white text-md">
<p>
  Вітаю,
  <br />
  Якщо в вас виникли проблеми з терміналом, скачайте інструкцію, та
  спробуйте полагодити його самостійно.
</p>
<p className="font-bold text-red-600">За допомогою даного сервісу можна:</p>
<ul className="list-disc">
  <li className="font-bold">Подавати заявки на доставку термострічки</li>
  <li className="font-bold">Повідомити про проблеми зі зв'язком</li>
  <li className="font-bold">Повідомити про проблеми з оплатою</li>
  <li className="font-bold">Повідомити про помилки «Tamper» / «Alert Irruption»</li>
</ul>
<p className="text-center">Нижче можливо завантажити інструкцію користування терміналом.👇</p>
<div className="w-full m-2">
  <a target='_blank' rel = "noopener noreferrer" href="https://docs.google.com/document/d/1_9jB41s8gvXhYO1mVXBACr4T-cOvIRzdLC62qX8ecB0/export?format=pdf">
    <img
      src="Frame-2.png"
      alt="Printecbot"
      className="mx-auto"
    />
  </a>
</div>
</div>
)
}
export default Hello;